<template>
  <section class="body-sign">
    <div class="center-sign">
      <a href="/" class="logo float-left">
        <img src="/img/logo-short-color.png" height="40" alt="Estoque Auto Logo" />
      </a>
      <div class="panel card-sign">
        <div class="card-title-sign mt-3 text-right">
          <h2 class="title text-uppercase font-weight-bold m-0"><i class="fas fa-user mr-1"></i> Autenticação</h2>
        </div>
        <div class="card-body">
          <form action="/auth/login" id="form" method="post">
            <div class="form-group mb-3">
              <label>E-mail</label>
              <div class="input-group">
                <input class="form-control form-control-lg" v-model="userData.Email" />
                <span class="input-group-append">
                  <span class="input-group-text">
                    <i class="fas fa-user"></i>
                  </span>
                </span>
              </div>
              <span class="text-danger"></span>
            </div>
            <div class="form-group mb-3">
              <div class="clearfix">
                <label class="float-left">Senha</label>
                <router-link :to="'/forgot/'+userData.Email" tabindex="-1" class="float-right">Esqueceu a senha?</router-link>
              </div>
              <div class="input-group">
                <input class="form-control form-control-lg" v-model="userData.Password" type="password" />
                <span class="input-group-append">
                  <span class="input-group-text">
                    <i class="fas fa-lock"></i>
                  </span>
                </span>
              </div>
              <span class="text-danger">{{error && error}}</span>

            </div>
            <div class="row">
              <div class="col-sm-8">
                <div class="checkbox-custom checkbox-default">
                  <input type="checkbox" checked="checked" />
                  <label for="RememberMe">Lembrar-me</label>
                </div>
              </div>
              <div class="col-sm-4 text-right">
                <button type="submit" @click="entrar()
" class="btn btn-primary mt-2" id="entrar" :disabled="loading">
                  {{loading ? 'Autenticando' : 'Entrar'}}
                </button>
              </div>
            </div>
            <span class="mt-3 mb-3 line-thru text-center text-uppercase">
              <span>ou</span>
            </span>
            <p class="text-center">Ainda não tem uma conta? <a href="https://estoqueauto.com.br">Cadastre-se</a> e teste grátis por 30 dias.</p>
          </form>
        </div>
      </div>
      <p class="text-center text-muted mt-3 mb-3">&copy; Estoque Auto. Todos os direitos reservados.</p>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import { login,logout } from '@/services/user.service.js';

export default {
    data() {
        return {
            userData: {
                Email: '',
                Password: '',
            },
            error: '',
            loading: false
        };
    },
    created() {

        // reset login status
        this.$root.authenticated = false;
        logout();
        console.log('created');
    },
    methods: {
        entrar: function () {
            this.loading = true;
            login(this.userData)
                .then(data => {
                    const { obj, msg } = data;
                    if (!obj) {
                        this.error = msg;
                    }
                    else {
                        this.$root.user = obj;
                        this.$root.authenticated = true;
                        this.$router.push('/');
                    }
                    this.loading = false;
                });              
        },
    },
}

</script>

<style scoped>
  .logo {
    margin-top: 1.7rem
  }
</style>
